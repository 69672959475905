var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-list-container text-unselect"},[_c('div',{class:[
      'content-box',
      {
        'is-web': !_vm.isMobile,
        'is-mobile': _vm.isMobile,
      },
    ]},[_c('h2',{staticClass:"content-title"},[_vm._v("NFTクリエーター")]),_c('div',{staticClass:"btn-back",on:{"click":_vm.goHome}},[_c('svg',{staticClass:"icon",attrs:{"t":"1684141995109","viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"2389","width":"24","height":"24"}},[_c('path',{attrs:{"d":"M946.5 505L560.1 118.8l-25.9-25.9c-12.3-12.2-32.1-12.2-44.4 0L77.5 505c-12.3 12.3-18.9 28.6-18.8 46 0.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8 12.1-12.1 18.7-28.2 18.7-45.3 0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204z m217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z","p-id":"2390","fill":"#ffffff"}})])]),(!_vm.isSuccess)?_c('div',[_c('section',{staticClass:"content-center"},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._m(0),_c('div',{staticClass:"option-list-box"},[_c('div',{staticClass:"option-list"},_vm._l((_vm.selectList),function(item,index){return _c('span',{key:index,on:{"click":function($event){return _vm.toSelectImg(item)}}},[_c('v-img',{class:[
                    'option-item',
                    {
                      'option-item-active': item.selectId === _vm.isSelect,
                    },
                  ],attrs:{"src":item.src,"width":"320","height":"320"}})],1)}),0)]),_c('section',{staticClass:"btn-operation"},[_c('v-btn',{class:[
                'btn-confirm',
                {
                  'btn-confirm-able': _vm.isSelectUrl.length > 0,
                },
              ],attrs:{"x-large":"","color":"primary","rounded":"","disabled":_vm.isSelectUrl.length === 0},on:{"click":_vm.btnConfirm}},[_vm._v(" Confirm ")])],1)])])]):_c('div',{staticClass:"is-ok"},[_vm._v("ok")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content-info-title"},[_c('span',{staticStyle:{"font-size":"22px","font-weight":"bold"}},[_vm._v(" おめでとうございます。")]),_c('br'),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" 選んだキーワードを元に専用のNFTが作成されました。"),_c('br'),_vm._v(" 以下の3つのNFTの中から、最も満足されるものを選んで確定してください。"),_c('br'),_vm._v(" それがあなたのNFTとなります。"),_c('br'),_c('span',{staticStyle:{"color":"red","font-size":"14px"}},[_vm._v(" ※NFTなので、変更はできません。")]),_c('br')])])
}]

export { render, staticRenderFns }