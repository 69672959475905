<template>
  <div class="option-list-container text-unselect">
    <div
      :class="[
        'content-box',
        {
          'is-web': !isMobile,
          'is-mobile': isMobile,
        },
      ]"
    >
      <!--      <div class="blue-bg"></div>-->
      <h2 class="content-title">NFTクリエーター</h2>
      <div class="btn-back" @click="goHome">
        <svg
          t="1684141995109"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2389"
          width="24"
          height="24"
        >
          <path
            d="M946.5 505L560.1 118.8l-25.9-25.9c-12.3-12.2-32.1-12.2-44.4 0L77.5 505c-12.3 12.3-18.9 28.6-18.8 46 0.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8 12.1-12.1 18.7-28.2 18.7-45.3 0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204z m217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"
            p-id="2390"
            fill="#ffffff"
          ></path>
        </svg>
      </div>
      <div v-if="!isSuccess">
        <section class="content-center">
          <div style="text-align: center">
            <p class="content-info-title">
              <span style="font-size: 22px; font-weight: bold">
                おめでとうございます。</span
              ><br />
              <span style="font-size: 16px">
                選んだキーワードを元に専用のNFTが作成されました。<br />
                以下の3つのNFTの中から、最も満足されるものを選んで確定してください。<br />
                それがあなたのNFTとなります。<br />
                <span style="color: red; font-size: 14px">
                  ※NFTなので、変更はできません。</span
                >
                <br />
              </span>
            </p>
            <div class="option-list-box">
              <div class="option-list">
                <span
                  @click="toSelectImg(item)"
                  :key="index"
                  v-for="(item, index) in selectList"
                >
                  <v-img
                    :src="item.src"
                    width="320"
                    height="320"
                    :class="[
                      'option-item',
                      {
                        'option-item-active': item.selectId === isSelect,
                      },
                    ]"
                  ></v-img>
                </span>
              </div>
            </div>
            <section class="btn-operation">
              <v-btn
                x-large
                color="primary"
                @click="btnConfirm"
                rounded
                :disabled="isSelectUrl.length === 0"
                :class="[
                  'btn-confirm',
                  {
                    'btn-confirm-able': isSelectUrl.length > 0,
                  },
                ]"
              >
                Confirm
              </v-btn>
            </section>
          </div>
        </section>
      </div>
      <div class="is-ok" v-else>ok</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { select_nft } from "@/api/user";
import { browser } from "@/utils/utils.js";
export default {
  name: "SelectImg",
  data() {
    return {
      isSelect: 0,
      selectList: [],
      loading: false,
      isSelectUrl: "",
      isMobile: false,
      isSuccess: false,
    };
  },
  watch: {
    selectImg: {
      handler(val) {
        this.show();
      },
      immediate: true,
    },
  },
  created() {
    this.$store.commit("system/isShowNavDrawer", false);
    this.show();
  },
  computed: {
    ...mapState({
      selectImg: (state) => state.user.selectImg,
    }),
  },
  methods: {
    show() {
      this.selectList = this.selectImg.map((i, k) => {
        return {
          src: i,
          selectId: parseInt(k + 1),
        };
      });
      this.isSelect = 0;
      this.isSelectUrl = "";
      this.loading = false;
      this.isMobile = browser?.versions?.mobile ?? false;
      this.isSuccess = false;
    },
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
    toSelectImg(item) {
      this.isSelect = item.selectId;
      this.isSelectUrl = item.src;
    },
    btnConfirm() {
      if (this.loading || this.isSelectUrl.length === 0) return;
      this.loading = true;
      select_nft({
        nft_url: this.isSelectUrl,
      })
        .then((res) => {
          this.loading = false;
          if (res.message === "ok") {
            this.isSuccess = true;
            this.goHome();
          }
        })
        .catch((err) => {
          console.log("err select_nft:", err);
          this.loading = false;
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: this.$t("network.refreshAgain"),
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.option-container {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background-color: #f8fafc;
}
.is-web {
  width: 640px;
  height: 100vh;
  margin: 0 auto;
}

.is-mobile {
  width: 100%;
  height: 100vh;
}

.btn-back {
  position: absolute;
  top: 16px;
  left: 6%;
  z-index: 2;
}

.content-box {
  position: relative;
  background: url(/static/image/nft_page_bg.png) no-repeat center center;
  background-size: cover;
  border-radius: 28px;
}

.option-list-container {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background-color: #f2f4f7;
  /*background: linear-gradient(135deg,  #FDF0EB 0%,#f7f5ff 100%);*/
}

.content-center {
  width: 90%;
  height: calc(100vh - 60px);
  overflow: hidden;
  position: absolute;
  background-color: rgba(26, 26, 26, 0.6);
  left: 5%;
  top: 60px;
  border-radius: 26px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.content-info-title {
  text-align: start;
  margin: 30px auto 20px;
  font-size: 20px;
  width: 88%;
  background-color: rgba(26, 26, 26, 0);
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.content-title {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
  color: #ffffff;
}

.option-list-box {
  width: 100%;
  height: 57vh;
  overflow-y: auto;
  box-sizing: border-box;
}

.option-list {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}

.option-item-img {
  width: 100%;
  height: 100%;
}

.option-item {
  margin: 20px auto;
  border-radius: 10px;
  border: 4px solid;
  border-image: linear-gradient(
      to right,
      #00e5e5 8.72%,
      #72a5f2 50.87%,
      #e961ff 91.3%
    )
    1;
}

.option-item-active {
  border: 4px solid;
  border-image: linear-gradient(
      to right,
      #328484 8.72%,
      #1e457d 50.87%,
      #4b144f 91.3%
    )
    1;
}

.btn-operation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0.7rem;
  z-index: 10;
}

.btn-submit {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 1.2rem;
}

.btn-confirm {
  width: 80%;
  bottom: 20px;
  background: linear-gradient(
    223.81deg,
    #328484 8.72%,
    #1e457d 50.87%,
    #4b144f 91.3%
  );
}

.btn-confirm-able {
  width: 80%;
  bottom: 20px;
  background: linear-gradient(
    223.81deg,
    #00e5e5 8.72%,
    #72a5f2 50.87%,
    #e961ff 91.3%
  );
}

.is-ok {
  font-size: 20px;
  width: 100%;
  text-align: center;
}
</style>
